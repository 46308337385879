#contact-form {
	top: 2rem !important;
	max-height: 95vh;
	border: none;
	border-radius: 5px;
	padding: 1rem 1rem 1rem 2rem;
	overflow: hidden;
	display: flex !important;
	flex-direction: column;
	h1 {
		font-family: $avenir-demi;
		color: $alt_red;
		font-size: 2.2rem;
		border-bottom: 1px solid $alt_red;
		padding-bottom: .667rem;
	}
	.modal-body {
		padding-right: 1rem;
		flex-shrink: 1;
		overflow-y: auto;
		p span {
			color: $l-blue;
		}
		form {
			label {
				color: $alt_red;
				padding-left: .333rem;
			}
			.row {
				display: flex;
				.input-pair {
					flex-grow: 1;
					padding-right: 1rem;
					&:last-of-type {
						padding-right: 0;
						padding-left: 1rem;
					}
				}
			}
			.form-foot {
				margin-top: .667rem;
				display: flex;
				padding-bottom: 1rem;
				.g-recaptcha {}
				.submit-button {
					align-self: flex-end;
					height: 40px;
					padding: .667rem 2rem;
					border-radius: 5px;
					background: lighten($alt-blue, 10%);
					color: white;
					font-family: $avenir-demi;
					margin-left: auto;
					transition: background $trans-typ;
				}
			}
		}
	}	
}