@charset 'utf-8';
@import 'variables';
@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=3d49d935-3032-4feb-9184-e6ec21a0ff65&fontids=721263,721305,721311");
@font-face{
    font-family:"AvenirNextLTW01-Condens_721299";
    src:url("../fonts/721299/f737d53f-f6ed-4f15-8fe7-925fb68a8851.eot?#iefix");
    src:url("../fonts/721299/f737d53f-f6ed-4f15-8fe7-925fb68a8851.eot?#iefix") format("eot"),url("../fonts/721299/7fb4cb78-601f-4bb2-995a-442252089573.woff2") format("woff2"),url("../fonts/721299/19db35b6-b3e8-400b-ae62-a3d37cc9fa56.woff") format("woff"),url("../fonts/721299/61860563-e9eb-40bd-844e-48e96af9d063.ttf") format("truetype");
}
@font-face{
    font-family:"AvenirNextLTW01-DemiCn";
    src:url("../fonts/721305/fe4d96a8-3161-4f0c-ac51-ed6a5df15918.eot?#iefix");
    src:url("../fonts/721305/fe4d96a8-3161-4f0c-ac51-ed6a5df15918.eot?#iefix") format("eot"),url("../fonts/721305/d268cd4d-987c-468a-a9bb-685df57a3892.woff2") format("woff2"),url("../fonts/721305/add96dac-4679-4981-9390-74fd5a5bf61f.woff") format("woff"),url("../fonts/721305/60f1dfd0-eea9-448c-84a0-e4e8d5ba7516.ttf") format("truetype");
}
@font-face{
    font-family:"AvenirNextLTW01-MediumC";
    src:url("../fonts/721311/10236419-1766-4da0-9a4c-83b4fffea025.eot?#iefix");
    src:url("../fonts/721311/10236419-1766-4da0-9a4c-83b4fffea025.eot?#iefix") format("eot"),url("../fonts/721311/c1ecdeb5-a61a-4207-ae23-0441885ba32d.woff2") format("woff2"),url("../fonts/721311/4a75c3b0-f2ef-402c-b54b-ed543933e9c5.woff") format("woff"),url("../fonts/721311/bb18d7e3-286e-4825-bf91-7fbeaf607d05.ttf") format("truetype");
}
@font-face{
    font-family:"Trenda W00 Regular";
    src:url("../fonts/5495517/9f67f46a-a677-4274-b1d7-eb9468d98da3.eot?#iefix");
    src:url("../fonts/5495517/9f67f46a-a677-4274-b1d7-eb9468d98da3.eot?#iefix") format("eot"),url("../fonts/5495517/6f27ae5f-d968-439a-af02-d19670ae0fd7.woff2") format("woff2"),url("../fonts/5495517/e45a54fb-5cae-4c92-a2f0-0b77fcbbe214.woff") format("woff"),url("../fonts/5495517/5a6516a3-2223-414e-9bc4-5f6cf2e613f1.ttf") format("truetype");
}
@font-face{
    font-family:"Trenda W00 Bold";
    src:url("../fonts/5495533/2562e870-3c9a-46d4-bf12-c84deacd2951.eot?#iefix");
    src:url("../fonts/5495533/2562e870-3c9a-46d4-bf12-c84deacd2951.eot?#iefix") format("eot"),url("../fonts/5495533/a59a363d-e688-4ddc-b63d-3c8033694cfa.woff2") format("woff2"),url("../fonts/5495533/fe9039c9-9a25-4208-ae42-3e08b820c28f.woff") format("woff"),url("../fonts/5495533/cadc0602-e68e-4be4-a474-3cb72361908f.ttf") format("truetype");
}



@import 'global';
@import 'index';
@import 'catalog';
@import 'projects';
@import 'catalog-line';
@import 'info';
@import 'contact';
@import 'contact-form';
@import 'vf-mask-mapping';
@import 'ar-styles';
@import 'project-page';