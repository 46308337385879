#catalog-line {
	.page-content {
		padding: 0 3.333rem;
		.line-description {
			padding: 1.2rem 3.33rem 0;
			position: relative;
			.back-link {
				position: absolute; 
				right: 3.33rem;
				top: 1.4667rem;
				font-style: initial;
				width: auto;
				background-color: $l_green;
				box-shadow: none;
			}
			.line-nav {
				display: inline-block;
				a { 
					color: $l_gray;
					&:after {
						border-color: lighten($l_gray, 5%) transparent transparent;
						margin-right: -1.5rem;
					}
					&.trigger {
						padding:0;
						border-bottom: 1px solid lighten($l_gray, 15%);
					}
				}
				ul.first-sub {
					background-color: lighten($alt_red, 15%);
					border: none;
					width: 13.333rem;
					box-shadow: 2px 3px 5px transparentize(black, .85);

					a {
						font-family: $avenir-demi;
						color: white;
						line-height: 1;
						transition: background-color $trans_typ;

						&:hover {
							background-color: $alt_red;
						}
					}
				}
			}
			h1 {
				display: inline;
				font-size: 2rem;
				font-family: $avenir-demi;
				padding-right: 2rem;
			}
			p {
				margin-bottom: 0;
				color: $alt-red;
				text-align: justify;
			}
			&.play, &.ipema {
				h1 {
					color: $l-red;
				}
			}
			&.woodlands, &.furnishings {
				h1 {
					color: $d-red;
				}
			}
			&.vines {
				h1 {
					color: $alt_yellow;
				}
			}
			&.climb, &.performance {
				h1 {
					color: $l_blue;
				}
			}
			&.combinations, &.landforms {
				h1 {
					color: $l_green;
				}
			}
		}
		.line-wrapper { 
			display: block;
			min-height: 100vh;
			padding-bottom: 4rem;

			>.grid-x {
				>div:nth-of-type(odd) {
					padding-right:3rem;
					padding-left: 3.333rem;
				}
				>div:nth-child(even) {
					padding-left: 3rem;
					padding-right: 3.333rem;
				}
			}
			.section-content { margin-top: -2rem; }
			.product {
				margin-top: 4rem;
				position: relative;

				&.terra-crit h5, &.play-sculpt h5 { color: $l-red; }
				&.woodlands h5, &.furnishings h5 { color: $d-red; }
				&.vines {
					h5 { color: $alt_yellow; }
					.product-img a {
						display: none;
					}
				}
				&.climbing h5, &.performance h5 { color: $l-blue; }
				&.combinations {
					h5 { color: $l-green; }
					.elevation {
						padding-top: 15px;
						img { height: 35px !important;}
					}
					&.ele-adjust {
						.elevation img {
							height: 85px !important;
							padding-left: 10px;
						}
					}
				}
				&.ropes h5{ color: $alt-red; }
				&.vf h5{color: $l-blue;}
				&.landforms h5 { color: $l-green;}
				// &.ipema {
				// 	&:after {
				// 		content: "";
				// 		position: absolute;
				// 		top: -1rem;
				// 		right: 2rem;
				// 		width: 4rem;
				// 		height: 4rem; 
				// 		background-image: url(../img/ipema_sm.png);
				// 		background-size: contain;
				// 		background-repeat: no-repeat;
				// 	}
				// }

				.product-wrapper { 
					border-bottom: 1px solid $l-gray;
					.details {
						h5, h6 {
							font-family: $avenir-demi;

							line-height: 1.4;
							margin-bottom: 0;
						}
						h6 { 
							color: $alt-red;
							margin-bottom: .2rem;
							display: none;
						}

						.data-row {
							color: $alt-red;
							font-family: $trenda;
							font-size: .8667rem;
							>div {
								width: 49%;
								display: inline-block;
								margin-bottom: .2rem;
								white-space: nowrap;
							}
						}
						.elevation {
							height: 50px;
							margin-top: .667rem;

							img { height: 100%; }
						}
					}
					.product-img {
						position: relative;
						display: flex;
						flex-direction: column;
						text-align: right;
						>div {
							margin: 0 1rem 1.667rem;
							display: flex;
							flex-grow:1;
							background-size: contain;
							background-repeat: no-repeat;
							background-position: bottom center;
						}
						.action-wrapper {
							position: absolute;
							bottom: 0;
							right: 0;
							flex-grow: 0;
							margin: 0;
							padding-bottom: 5px;
							justify-content: flex-end;
							align-items: baseline;
							a {
								line-height: 0;
								font-family: $avenir;
								font-size: .8667rem;
								&.download-trigger {
									display: none;
								}
							}
							&.open {
								a {
									&.download-trigger {
										display: inline;
									}
									&.download-form-trigger {
										display: none;
									}
								}
							}
						}
					}
				}
				&.vf {
					margin-top: 2rem;
					&:first-of-type {
						margin-top: 4rem;
					}
					.product-wrapper {
						h5 {
							font-family: $avenir-demi;
							line-height: 1.4;
							margin-bottom: 1.33rem;
							width: 100%;
						}
						.product-img {
							height: 200px;
							.action-wrapper {
								position: absolute;
								bottom: 3px;
								right: 2rem;
							}
						}
						.details {
							display: flex;
							flex-direction: column;
							.data-row {
								&:nth-of-type(4) {
									flex-grow: 1;
								}
							}
						}
						.components {
							color: $alt-red;
							font-family: $trenda;
							font-size: .8667rem;
							ul li {
								list-style-type: circle;
								margin-bottom: 2px;
								&.climber >div {color: $alt-red;}
								&.rope >div {color: $alt-red;}
							}
						}
					}
					@media (max-width: 640px) {

					}
				}
				&.ele-adjust {
					.elevation img {
						height: 100px !important;
						margin-top: -50px;
					}
				}
			}
		}
	}
	&.ipema {
		.line-wrapper .product.ipema:after { display: none; }
	}
	#via-ferrata {
		.line-narrative {
			p{
				margin-top: .67rem;
				margin-bottom: 1rem;
			}
		}
		h3 {
			font-family: $avenir-demi;
			color: $alt-red;
			font-size: 1.6rem;
		}
		.vf-park {
			margin-top: 1.33rem;
			width: 100%;
			border-bottom: 1px solid lighten($alt-red, 33%);
			padding-bottom: 1rem;
			h3 {
				margin-bottom: 1.5rem;
			}
			.img-container {
				position: relative;
				.mask {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					transition: .05s opacity ease;
					&.hover {
						opacity: .8;
					}
				}
			}
			.vf-items {
				h5 {
					font-family: $trendaBold;
					font-size: 1rem; 
				}
				padding-left: 5rem;
				ul {
					list-style: none;
					margin-left: 3px;
					column-count: 2;
					li {
						padding-left: 0;
						line-height: unset;
						a {
							display: inline-block;
							height: 1.8rem;
							line-height: 1.8rem;
							transition: $trans_typ color;
							&
							span {
								display: none;
							}
						}
					}
				}
				.rock-list {
					h5, a { 
						color: $l-blue; 
					}
					a:hover { color: darken($l-blue, 30%); }
				}
				.rope-list {
					padding-top: 1rem;
					h5, a {
						color: $alt-red;
					}
					a:hover { color: darken($alt-red, 30%); }
				}
			}
		}
		@media (max-width: 1024px) {
			.vf-items {
				padding-top: 2rem;
				padding-left: 0 !important;
				.rock-list {
					ul { column-count: 1; }
				}
				.rope-list {
					padding-top: 0 !important;
				}
			}
		}
		.vf-systems {
			p {
				text-align: left;
			}
			border-bottom: 1px solid lighten($alt-red, 33%);
			padding-bottom: 1rem;
		}
		.vf-equation {
			margin-top: 1rem;
			>div {
				position: relative;
				display: flex;
				flex-direction: column;
				.img-wrapper {
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					flex-grow: 1;
				}
				h6 { 
					font-family: $trendaBold; 
					color: $l-blue;
				}
				div {
					margin-top: auto;
					text-align: center;
				}
				&.climber, &.rope {
					padding: 0 3rem;
					.constructor {
						position: absolute;
						top: 27%;
						font-size: 45px;
						color: $alt-red;
					}
				}
				&.climber {
					h6 { color: $l-blue; }
					.img-wrapper {
						max-height: 60%;
						background-image: url(../img/catalog/via-ferrata/vf-eq-climber.png);
					}
					.constructor {left: 100%;}
				}
				&.rope {
					// h6 { color: $l-green-des; }
					.img-wrapper {
						max-height: 70%;
						background-image: url(../img/catalog/via-ferrata/vf-eq-rope.png);
					}
					.constructor { right: 3rem; }
				}
				&.system {
					// h6 { color: $l-red; }
				}
			}

		}
		.section-banner-label {
			background-color: $l-blue;
			padding: 0 2rem;
			border-radius: 0 0 5px 5px;
			h4 {
				color: white;
				font-family: $trendaBold;
				margin-bottom: 0;
				line-height: 3rem;
			}
			&.preconfig ~ .line-wrapper { border-bottom: 1px solid lighten($alt-red, 33%); }
		}
		.section-header {
			padding-left: 3.33rem;
			margin-top: 2rem;
			h4 {
				font-family: $trendaBold !important;
				color: $l-blue;
				margin-bottom: 0;
			}
			&.ropes h4{ color: $alt-red; }
			&.preconfig h4{ color: $d-red; }
		}
		.section-content .ropes {
			margin-top: 3rem;
			.product-img { 
				height: 125px; 
			}
		}
	}
}
@media (max-width: 900px) {
	.line-description {
		padding-top: 0 !important;
		.back-link {
			position: relative !important;
			display: block;
			margin-right: 50%;
			text-align: left;
			right: auto !important;
			top: auto !important;
			padding-left: 0;
			margin-bottom: 0;
			background: transparent !important;
			color: $l_gray;
			font-style: italic !important;
			margin-left: -3rem;
		}
	}
	.product.vf .product-wrapper .components { display: none; }
}
@media (max-width: 900px) {
	.vf-systems { display: none; }
}
@media (max-width: 600px) {
	#catalog-line.page-wrapper {
		padding: 0 !important;
	}
	.page-content {
		padding: 0 1.667rem !important;
	}
	.section-content {
		.product {
			padding: 0 !important;
			margin-top: 1rem !important;
			.product-wrapper {
				padding-bottom: 2rem;
				.product-img {
					>div {
						margin: 1rem 0 1rem 2rem !important;
						&.action-wrapper {
							margin: 0 !important;
							position: absolute;
							right: 0;
							bottom: 0;
						}
					}
					a:not(.ar-content-trigger) { display: none; }
				}
			}
			&:first-child {
				margin-top: 4rem !important;
			}
			&.ipema {
				&:after {
					width: 3rem !important;
				    right: 33% !important;
				    top: auto !important;
				    bottom: 0;
				}
			}
		}
	}
}
@media (max-width: 640px) {
	#catalog-line {
		.page-content {
			.line-description {
				.back-link {
					margin-left: 0 !important;
				}
			}
		}
		#via-ferrata {
			.instruction-copy {
				p {
					padding: 0 !important;
					&:first-child {
						margin-bottom: 1rem;
					}
				}
			}
			.section-header {padding-left: 0;}
		}
	}
}


//------------------------------ AR New feature overrides 2/1/21 ---------------------
			
#catalog-line .page-content {
	.line-description >div{
		.back-link {
			right: 0;
			top:7px;
			height: 24px;
			line-height: 24px;
			background-color: transparent;
			border: 1px solid $alt-red;
			color: $alt-red;
			padding: 0 1rem;
			opacity: .5;
			transition: $trans_typ opacity;
			&:hover, &:active {
				opacity: 1;
			}
		}
		&.new-feature {
			padding-left: 6rem;
			padding-right: 3rem;
			display: flex;
			>div, a {
				padding-bottom: 1rem;
			}
			.ar-info-trigger {
				flex-shrink: 0;
				display: inline-block;
				flex-direction: column;
				width: 120px;
				height: 120px;
				background-color: $d-red;
				border-radius: 60px;
				text-align: center;
				box-shadow: 2px 2px 3px transparentize(black, .7);
				transition: $trans_typ box-shadow;
				&:hover, &:focus {
					box-shadow: 2px 2px 3px transparentize(black, .4);
				}
				img {
					display: block;
					margin: 10px auto 0;
				}
				span {
					clear: both;
					color: white;
					font-size: .9333rem;
				}
			}
			.feature-description {
				padding-left: 2rem;
				h5, p { color: $d-red; }
				h5 { font-family: $trendaBold ; }
				p { text-align: left; }
			}
		}
	}
	.line-wrapper .product .product-wrapper {
		.product-img.has-ar {
			position: relative;
			.action-wrapper {
				a {
					padding-right: 1.5rem;
					&.ar-content-trigger {
						display: inline-block;
						width: 25px;
						height: 25px;
						padding: 0;
						background-image: url(../img/3d-icon-small.png);
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}
@media (max-width: 1100px) {
	#catalog-line .page-content .line-description >div {
		.back-link {
			display: none;
		}
		&.new-feature {
			padding-left: 4rem;
			padding-right: 0;
			.ar-info-trigger {
				width: 90px;
				height: 90px;
				img {
					height: 40px;
				}
			}
			h5 { margin-bottom: 0; }
		}
	}
}
@media (max-width: 640px) {
	#catalog-line .page-content .line-description div.new-feature {
		padding-left: 0rem;
		margin-top: 2rem;
		.ar-info-trigger {
			width: 75px;
			height: 75px;
			img { height: 45px; }
			span { display: none; }
		}
	}
	#catalog-line .page-content .line-wrapper .product .product-wrapper .product-img.has-ar .action-wrapper {
		margin-bottom: -1.33rem !important;
	}
}



// position: absolute; 
// 				right: 3.33rem;
// 				top: 1.4667rem;
// 				font-style: initial;
// 				width: auto;
// 				background-color: $l_green;
// 				box-shadow: none;












