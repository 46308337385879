#project-page {
	height: calc(100vh - 6rem);
    overflow: hidden;
    padding: 1rem 2rem 0;
    position: relative;
    margin-bottom: 2rem;
    border-bottom: 1px solid $l-gray;
	.content-wrapper{
		height: 100%;
		.orbit {
			height: 100%;
		}
		nav.orbit-bullets {
			margin: 0;
			padding: 0;
			padding-right: 1rem;
			height: 100%;
			overflow-y: auto;
			border-radius: 5px;
			button {
				width: 100%;
				padding-top: 100%;
				border-radius: 0;
				margin: 5px 0;
				position: relative;
				border-radius: 5px;
				overflow: hidden;
				cursor: pointer;
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: all ease .15s;
					&.thumb-top {
						object-position: top;
					}
					&.thumb-left {
						object-position: left;
					}
					&.thumb-right {
						object-position: right;
					}
				}
				&:hover {
					img {
						transform: scale(1.1);
					}
				}
				&.is-active {
					img {
						transform: scale(1.1);
					}
					&:after {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						border: 3px solid $slate-24;
						border-radius: 5px;
					}
				}
			}
		}
		.focus-panel {
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow: hidden;
			padding-left: 1rem;
			.orbit-wrapper {
				flex-shrink:1;
				flex-grow: 1;
				position: relative;
				.orbit-controls {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				ul.orbit-container {
					height: 100% !important;
					width: 100%;
					max-height: unset;
					min-height: unset;
					border-radius: 5px;
					li, figure, img{
						height: 100%;
						width: 100%;
					}
					img {
						object-fit: cover;
						&.vert {
							object-fit: contain;
							background-color: $slate-24;
						}
						&.image-bottom {
							object-position: center bottom;
						}
					}
					.orbit-caption {
						display: none;
					}
				}
			}
			.project-details {
				.project-name {
					margin-top: .67rem;
					display: flex;
					align-items: baseline;
				}
				h1 {
					font-family: $avenir-demi;
					font-size: 2rem;
					color: #1f252f
				}
				span {
					font-family: $avenir;
					font-size: 2rem;
					color: #1f252f;
					padding-left: .5rem;
					margin-left: .5rem;
					border-left: 1px solid #1f252f;
					line-height: 1;
				}
				p {
					margin-bottom: 0;
					font-size: 1.2rem;
				}
			}
		}
	}
}
.splash-gallery {

	h3 {
		color: $red-24;
		margin: 1rem 0 3rem;
		text-align: center;
	}
	.splash-gallery-wrapper {

		.image-tile{ 
			height: 23.44vw;
			margin-bottom: 5px;
			.tile-wrapper {
				width: 100%;
				height: 100%;
				overflow: hidden;
				position: relative;
				transition: outline ease .2s;
				border-radius: 5px;
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				transition: all ease .3s;
				&.image-center {
					background-position: center;
				}
			}
			.tile-info {
				position: absolute;
				bottom: 0;
				left: 0;
				border-radius: 0 0 5px 5px;
				background: rgba(255,255,255,.8);
				width: 100%;
				padding: 5px 10px;
				opacity: 0;
				transition: opacity ease .3s;
				z-index: 10;
				h4 {
					font-weight: 600;
					font-family: $trendaBold;
					color: $slate-24;
					margin-bottom: 0;
				}
				div {
					font-family: $avenir;
					font-size: 18px;
					color: $slate-24;
					margin-top: -.33rem;
				}
			}
			&:hover {
				.tile-wrapper {
					outline: 2px solid $lightBlue-24;
				}
				a {
					transform: scale(1.1);
				}
				.tile-info {
					opacity: 1;
				}
			}
		}
	}
}