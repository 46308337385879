#contact, #thankyou {
	.page-content {
		padding: 0 3.333rem;
		min-height: calc(100vh - 276px);
		h1 {
			margin-top: 2rem;
			padding-left: 2rem;
			font-family: $avenir-demi;
			color: $alt_red;
			font-size: 2.2rem;
		}
		>div {
			margin-bottom: 2rem;
		}
		form {
			border-radius: 5px 0 0 5px;
			background: $l_gray;
			padding: 2rem;
			padding-bottom: 4rem;
			position:relative;
			.input-group {
				display: block;
				label, input, textarea {
					font-family: $trenda;
					border: none;
					outline: none;
				}
				input, textarea {
					color: $alt-blue;
					&:focus {
						outline: 1px solid $alt_red;
						outline-offset: 0;
					}
				}
				label {
					color: $alt_red;
					padding-left: .333rem;
				}	
			}
			.input-left {
				padding: 0 2rem 0 0;

				.submit {
					padding: .667rem 2rem;
					border-radius: 5px;
					background: lighten($alt-blue, 10%);
					color: white;
					font-family: $avenir-demi;
					float: right;
					margin-top: .667rem;
					margin-right: .333rem;
					transition: background $trans-typ;
					box-shadow:none;
					border:none;

					&:hover {
						cursor: pointer;
						background: $alt-blue;
					}
				}
				.submit[disabled] {
					opacity:.5;
				}
				.cap-wrap {
					overflow: hidden;
					>div {
						margin:auto;
						transform: scale(.75);
					}
				}
				p {
					position: absolute;
					bottom: 0;
					color: $alt-red;
					font-style: italic;
				}
			}
			.input-right {
				display: flex;
				flex-direction: column;
				margin-bottom: 0;
				textarea {
					flex-grow: 1;
					margin-bottom: 0;
				}
			}
		}
		.physical-contact {
			background-color: $alt_yellow;
			color: darken($l_gray, 30%);
			border-left: 2px solid white;
			padding: 2rem;
			border-radius: 0 5px 5px 0;
			font-family: $trendaBold;

			p {
				&:first-child {
					font-size: 1.4rem;
					
				}
				&:not(:first-child, :last-child) {
					padding-left: 1rem;
					color: white;
				}
			}
			.contact-encoding {
				color: white;
				padding-left: 1rem;
			}
		}
		>p {
			padding: 0 2rem;
		}
	}
}
@media (max-width: 1024px) {
	#contact, #thankyou {
		 .page-content {
			form {
				border-radius: 5px 5px 0 0;
			}
			.physical-contact{
				border-radius: 0 0 5px 5px;
				border-left: none;
				border-top: 2px solid white;
			}
		}
	}
}
@media (max-width: 800px) {
	#contact, #thankyou {
		.page-content { margin-top: 5rem;}
	}
}
@media (max-width: 640px) {
	#contact, #thankyou {
		 .page-content {
			form {
				padding-bottom: 7rem;
				.input-left {
					padding-right: 0;

					button {
						position: absolute;
						right: 2rem;
						bottom: 3rem;
					}
				}
				.input-right {
					textarea {
						min-height: 8rem;
					}
				}
			}
		}
	}
}

#thankyou {
	form {
		text-align: center;
		justify-content: center;
		align-content: center;

		h2, h6 {
			width: 100%;
			color: $alt-red;
			font-family: $trenda;
			font-weight: 600;
		}
		h2 {		}
		h6 {
			font-size: 1.2rem;

		}
	}
}