.product.vf {
	.vfc001-d {
		.mask {
			width: 100%;
			height:100%;
			background-image: url(../img/catalog/masks/test-mask.png);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			opacity:0;
			transition: opacity .3s ease;
			&.show-mask {
				opacity:.75;
			}
		}
	}
}