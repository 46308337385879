
#catalog {
	#search-bar {
		padding: 0 3.333rem;
		height: 40px;
		background-color: transparentize($primary-color, .2);
		>div, >span, >a {
			color: white;
			line-height: 40px;
			display: inline-block;
		}
		>span:first-of-type {
			font-weight: 600;
			padding-right: 3.333rem;
		}
		>span:last-of-type {
			padding: 0 2.667rem;
		}
		a { 
			font-family: $avenir;
			font-style: italic;
			font-weight: 600;
			text-decoration: underline;
			opacity: .8;
			transition: opacity $trans_typ;
			&:hover {
				opacity: 1;
			}
		}

		.control-group {
			label { 
				font-size: 1rem;
				color: white;
				display: inline;
				padding-right: .333rem;
			}
			input {
				border: none;
				height: 23px;
				width: 100px;
				&:focus {
					outline: 2px solid $l-green;
					&~button {
						border-color: $l-green;
					}
				}
			}
			button {
				font-family: $avenir-med;
				color: white;
				height: 23px;
				width: 23px;
				border: 1px solid white;
				margin-left: -5px;
				font-size: .9333rem;
			}
		}
	}
	.page-content {
		padding: 0 3.333rem;
		#search-results { display: none; }
		#product-lines {
			>.grid-x {
				>div:nth-of-type(odd) {
					padding-right:3rem;

					&.highlight:before {
						left: -2rem !important;
						width: calc(100% + 2rem);
					}
				}
				>div:nth-child(even) {
					padding-left: 3rem;
				}
			}
			.section-des {
				margin-top: 2rem;
				margin-bottom: 3rem;
				color: $alt-red;
				h5 {
					font-weight: bold;
					margin-bottom: 0;
				}
				>div:not(.btn-holder) p {margin-bottom: 0;}
				.btn-holder {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					padding-bottom: 6px;
				}
				.btn-holder {
					padding-left: 1rem;
					align-items: center;
					.button {
						margin: 0;
						height: 4rem;
						width: 16.67rem;
						font-size: 1.2rem;
						padding: 0;
						font-style: initial;
						display: flex;
					    justify-content: center;
					    align-items: center;
					}
				}
				.line-nav {
					margin-top: 2rem;
					font-family: $avenir;
					ul {
						list-style: none;
						margin: 0;
						li {
							display: inline-block;
							line-height: 1;
							&:not(:last-child) {
								border-right: 1px solid $alt-red;
								}
							a {
								color: $alt-red;
								padding: 0 5px;
								font-size: .867rem;
								transition: $trans-typ color;
								&:hover {
									color: $l-blue;
								}
							}
						}
					}
				}
				.featured-product {
					padding-left: 0 !important;
					max-height: 175px;
					#feature-container {
						width: 100%;
						height: 100%;
						display: flex;
						position: relative;
						padding-left: 0;
						padding: 10px 0 10px 10px;
						&:before {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: calc(100% + 3.33rem);
							height: 100%;
							background-color: $alt-red;
							border-radius: 10px 0 0 10px;
							box-shadow: 2px 2px 3px transparentize(black, .8);
							transition: box-shadow ease .3s;
							z-index: -1;
						}
						&:hover:before {
							box-shadow: 3px 3px 4px transparentize(black, .5);
						}
						.image-block {
							height: 100%;
							background-color: white;
							border-radius: 7px;
							padding: 5px;
							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
						.feature-description {
							display: flex;
							flex-direction: column;
							padding-left: 2rem;
							color: white;
							p {
								font-size: .867rem;
								margin-bottom: auto;
							}
						}
						a {
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
						}
					}
				}
			}
			.section-content {
				.line {
					min-height: 10rem;
					margin-bottom: 3.333rem;
					color: $alt-red;
					position:relative;
					>div:first-child {
						display: flex;
						flex-direction: column;

						h5 { 
							font-family: $avenir-demi;
							line-height: 1;
							margin-bottom: .333rem;
						}
						p {
							margin-bottom: 0;
							flex-grow: 1;
							font-size: .8667rem;
							padding-right: 2rem;
							margin-bottom: .667rem;
						}
						div {
							padding-bottom: 1rem;

							a.act_link {
								font-family: $avenir-demi;
								color: $d-red;
								background-image: url(../img/act_link_arrow.png);
								background-repeat: no-repeat;
								background-position: right center;
								width: auto;
								padding-right: 1.33rem;
								transition: color $trans-typ;
								&:hover {
									color: lighten($d-red, 5%);
								}
							}
						}
					}
					.product-image {
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						padding: 1rem 0;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
							max-height: 150px;
						}
					}
					// &.ferrata .product-image {
					// 	background-image: url(../img/ferrata_line_img.png);
					// }
					// &.terra .product-image{
					// 	background-image: url(../img/terra_line_img.png);
					// }
					// &.play .product-image{
					// 	background-image: url(../img/play_line_img.png);
					// }
					// &.wood .product-image{
					// 	background-image: url(../img/wood_line_img.png);
					// }
					// &.vines .product-image{
					// 	background-image: url(../img/vines_line_img.png);
					// }
					// &.climb .product-image{
					// 	background-image: url(../img/climb_line_img.png);
					// }
					// &.perform .product-image{
					// 	background-image: url(../img/perform_line_img.png);
					// }
					// &.combo .product-image{
					// 	background-image: url(../img/combo_line_img.png);
					// }
					// &.furnishings .product-image{
					// 	background-image: url(../img/furnishings_line_img.png);
					// }
					// &.landforms .product-image {
					// 	background-image: url(../img/land-line-img.png);
					// }
					&.ipema-line .product-image {
						padding: 1rem;
						div {
							background-image: url(../img/ipema-web.png);
							background-size: contain;
							background-position: left center;
							background-repeat: no-repeat;
							width: 100%;
							height: 100%;

							a {
								display: block;
								width: 100%;
								height: 100%;
								max-width: 150px;
							}
						}
					}
					&:before {
						transition: background-color $trans-typ;
						content: "";
						position: absolute;
						left: 0;
						top: -1rem;
						height: calc(100% + 2rem);
						width: 100%;
						background-color: white;
						z-index: -1;
						border-radius: 5px;
					}
					&.highlight:before {
						background-color: transparentize($l-blue, .93);
					}
					&.highlight {
						.line-trigger {
							text-decoration: underline;
						}
					}
				}
				a.line-nav-trigger {
					position:absolute;
					display: block;
					width: 100%;
					height: 100%;
					&:hover {
					}
				}
			}
		}
	}
}
.ipema .page-content .line-description >div p {
	span {color: black;}
	a { color: $alt-red ; text-decoration:underline; }
}

@media (max-width: 1025px) {
	.page-content {
		#product-lines {
			.grid-x { 
				padding: 0 !important;
				&#feature-container {
					padding: 10px !important;
					margin-top: 10px;
				}
				.line {
					div {

						p {
							text-align: justify;
							padding-right: 4rem !important;
						}
					}
				}
			}
			.product-image { max-width: 300px;}
		}
	}
}	

@media (max-width: 640px) {
	#catalog.page-wrapper { padding: 0 !important; }
	.page-content {
		padding: 0 1.667rem !important;
		#product-lines {
			.section-des {
				margin-bottom: 1.667rem !important;
				h5 {
					margin-top: .67rem;
					margin-bottom: .33rem !important;
				}
				div {
					padding-right: 0 !important;
					// &:last-child { display: none;}
				}
				.btn-holder {
					justify-content: center !important;
					margin-top: 1.33rem;
					margin-bottom: 1rem;
				}
			}
			.section-content {
				.line {
					margin-bottom: 1.667rem !important;
					padding-bottom: 1.667rem !important;
					border-bottom: 1px solid $l_gray;
					width: 100%;
					>div {
						p {
							padding-right: 0 !important;
							text-align: left;
						}
						>div {
							text-align: right;
						}
					}
					&.highlight:before {
						left: -2rem !important;
						width: calc(100% + 4rem) !important;
						top: -1.667rem !important;
					}
					.product-image {
						height: 20vh;
						max-width: 80%;
						margin:auto;
					}
				}
			}
		}
		.line-description {
			padding: 0 !important;
		}
	}
	.section-content {
		.product {
			padding: 0 !important;
			.elevation { display: none; }
		}
	}
}
@media (max-width: 920px) {
	
}

//ipad pro

@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  	#catalog.page-wrapper .page-content .product-lines .ipad-padding {
  		padding-left: 3rem !important;
  	}
}

