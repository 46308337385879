#projects {

	.project {
		height: 30vw;
		overflow: hidden;
		position: relative;
		border-bottom: 2px solid white;
		.focus {display: none;}

		&.left {
			.focus {
				left: 16.67vw;
				right: auto;
				transform: translateX(-50%);
			}
			.text-block {
				padding-right: 2rem;
				// h1, span { padding-right: 50%;}
				&:after {
					right: -1px;
					left: auto;
				}
			}
		}
		&.right{
			.text-block {
				// padding-left: 12.5vw;
				padding-right: 2rem;
				&:after {
					left: -1px;
					right: auto;
				}
				a {
					// left: 12.5vw;
				}
			}
			.focus {
				right: 16.67vw;
				left: auto;
				transform: translateX(50%);
			}
			>div:not(.focus) {
				float: right;
			}
		}
		&.ones{
			.text-block {
				background-color: $l-red;
			}
		}
		&.twos{
			.text-block {
				background-color: $l-blue;
			}
		}
		&.threes{
			.text-block {
				background-color: $l-green;
			}
		}
		.text-block {
			color: white;
			font-family: $avenir;
			padding-left: 40px;
			padding-top: 2rem;
			position: relative;
			h1 {
				font-family: $avenir-demi;
				font-size: 1.4rem; 
				color: white;
				margin-bottom: 0;
			}
			span {
				font-size: 1.2rem;
			}
			p {
				margin-top: .667rem;
				text-align: justify;
			}
			a {
				font-family: $avenir-demi;
				border: 1px solid white;
				width: auto;
				padding: .667rem 1rem;
				color: white;
				position: absolute;
				bottom: 2rem;
				left:40px;
				margin-bottom: 0;
				font-style: initial;
				box-shadow: none;
				height: auto;
				&.red {
					background-color: transparentize($d-red, 1);
					&:hover {
						background-color: transparentize($d-red, .5);
					}
				}
				&.blue {
					background-color: transparentize($d-blue, 1);
					&:hover {
						background-color: transparentize($d-blue, .5);
					}
				}
				&.green {
					background-color: transparentize($d-green, 1);
					&:hover {
						background-color: transparentize($d-green, .5);
					}
				}
			}
			&:after {
				content: "";
				height: 100%;
				width: 2px;
				background-color: white;
				position: absolute;
				top: 0;
				z-index: 2;
			}
		}
		.feature {
			height: 100%;
			position: relative;
			background-size: cover;
			background-position: center;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.focus {
			position: absolute;
			height: calc(25vw - 60px);
			width: calc(25vw - 60px);
			border-radius: 10px;
			overflow: hidden;
			top: 30px;
			border:5px solid white;
			z-index: 3;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&.feature-align-top .feature img {
			object-position: top;
		}
	}
	.themes {
		height:25vw;
		overflow:hidden;
		margin-bottom: 3px;
		margin-top: 1px;
		.theme-wrapper {
			height: 100%;
			overflow: hidden;
			position: relative;
			h1 {
				position: absolute;
				top: 0;
				left: 0;
				font-family: $avenir-demi;
				font-size: 1.4rem; 
				color: $alt-red;
				padding: .5rem 1rem;
				background-color: transparentize(white, .3);
				border-radius: 0 0 5px 0;
			}
			a {
				position:absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 0;
				box-shadow: none;
				background-color: transparentize(black, 1);
				transition: background-color .3s ease;
				&:hover {
					background-color: transparentize(black, .8);
				}
			}
			&:first-child {
				border-right: 2px solid white;
			}
			&:nth-child(2) {
				border-left: 1px solid white;
				border-right: 1px solid white;
			}
			&:last-child {
				border-left: 2px solid white;
			}
			img {
				object-fit: cover;
				object-position: bottom;
				width: 100%;
				height: 100%;
			}
		}
	}
}
.project-gallery {
	border-radius: 5px;
	height: 80vh;
	padding: 2rem 3rem;
	background-color: transparentize(white, .05);
	&:focus {outline: none;}
	.content-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		.close-button {
			font-size: 3rem;
			line-height: .5;
		}
		.description {
			padding-bottom: 1rem;
			color: $alt-red;
			h1 {
				font-size: 1.8rem;
				display: inline;
				font-family: $avenir-demi;
			}
			span {
				margin-left: 1.2rem; 
				padding-left: 1.2rem;
				border-left: 1px solid $l_gray;
			}
		}
		.carosel-wrapper {
			position: relative;
			flex-grow: 1;
			flex-shrink: 1;
			.orbit {
				.orbit-wrapper {
					.orbit-controls {}
					.orbit-container {
						&:focus {
							outline: none;
						}
						img {
							max-height: 100% !important;
							max-width: 100% !important;
							vertical-align: unset !important;
						}
						.orbit-slide {
							height: 100%;
							figure {
								height: 100%;
								figcaption { display: none;}
							}
							&.is-active {
								height: 100%;
								figure {
									text-align: center;
									height: 100%;
								}
							}
						}
					}
				}
			}
			.orbit-bullets {
				margin-bottom: 0;
				margin-top: 1rem;
				button {
					width: 1rem;
					height: 1rem;
					background-color: transparentize(white, .5);
					position: relative;
					cursor: pointer;
					border: 1px solid #303030;
					&.is-active {
						background-color: #101010;
						// &:after {
						// 	content: "";
						// 	position: absolute;
						// 	width: 100%;
						// 	height: 100%;
						// 	border-radius: 50%;
						// 	border: 1px solid transparentize($d-green, .5);
						// 	top: 0;
						// 	left: 0;
						// }
					}
				}
			}
		}
	}
}
.page-content {
	padding: 2rem 4rem;
	margin: auto;
	font-size: 1.2rem;
	color: $brown-24;
}
@media (max-width: 1200px) {
	#projects .project .text-block p {
		display: none;
	}
}
@media (max-width: 1024px) {
	#projects .project {
		.text-block {
			padding: 1rem 2rem;
			a {
				bottom: 1rem;
				left: 2rem;
			}
		}
		&:nth-child(even) {
			.text-block {
				padding: 1rem 2rem;
				&:after {
					left: -1px;
					right: auto;
				}
				a {
					left: 2rem;
				}
			}
		} 
		.focus {
			display: none;
		}
	}
}
@media (max-width: 900px) {
	#projects .project {
		.text-block {
			padding: 1rem;
			h1 {
				font-size: 1.2rem;
				margin-bottom: 0;
			}
			span {
				font-size: 1rem;
			}
			a {
				padding: .5rem 1rem;
			}

		}
	}
	#projects .themes {
		height: unset;
		.theme-wrapper {
			height: 33vh;
		}
	}
}
@media (max-width: 812px) {
	.reveal#gallery-modal {
		height: 90vh;
	}
}

@media (min-height: 600px) {
	.orbit-container { 
		min-height: calc(80vh - 9.4667rem); 
		max-height: calc(80vh - 9.4667rem);
	}
}
@media (max-width: 640px) {
	#projects.page-wrapper {
		padding: 0 !important;

		.project {
			height: 33.33vh;

			.text-block {
				position: absolute;
				bottom: 0;
				z-index: 10;
				padding-right: 7rem;
				h1 { 
					display: inline;
					width: auto;
					padding-right: 1rem;
				}
				span {
					padding-right: 0;
				}
				a {
					left: auto;
					right: 1rem;
				}
			}
			&:nth-child(even) .text-block {
				position: absolute;
			}
		}
	}
	.project-gallery {
		min-height: 50vh;
		top: 5vh !important;
		padding: 2rem .667rem;
		.description {
			padding: 1rem;
			h1 { font-size: 1.4rem !important; }
		}
		.orbit {
			min-height: 50vh;
			.orbit-wrapper {
				height: 100%;
			}
		}
	}
	.orbit-container { 
		min-height: calc(50vh - 9.4667rem) !important; 
		max-height: calc(50vh - 9.4667rem) !important;
	}
}

.is-reveal-open {
	overflow: visible !important;
}



.project-gallery {
	.close-button { 
		color: white;
		text-shadow: 1px 2px 3px transparentize(black, .75);
	}
	padding: 0;border:none;
	.content-wrapper {
		.description{
			z-index: 10;
			padding: .667rem 2rem;
			background-color: transparentize(white, .3);
			border-radius: 0 0 5px 0;
			position:absolute;
			h1, span {
				color: darken($alt-red, 10%);
			}
		}
		.carosel-wrapper{
			position:absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			.orbit {
				width: 100%;
				height:100%;
				.orbit-wrapper {
					height: 100%;
					position: relative;
					.orbit-controls{
						button {
							background-color: transparentize(white, .5);
							box-shadow: 0 0 3px 1px transparentize(black, .8);
							color: darken($alt-red, 10%);
							&.orbit-next {
								border-radius: 3px 0 0 3px;
							}
							&.orbit-previous {
								border-radius: 0 3px 3px 0;
							}
						}
					}
					ul.orbit-container {
						position:absolute;
						left: 0;
						top: 0;
						min-height: unset;
						max-height: unset;
						width: 100%;
						height: 100% !important;
						li.orbit-slide {
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
				.orbit-bullets {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					margin-top: 0;
					padding: 0 1rem .5rem;
					border-radius: 3px 3px 0 0;
				}
			}
		}
	}
}
@media screen and (orientation: portrait)
	and (max-width: 767px) {
	.reveal#gallery-modal {
		z-index:10;
		padding: 2.33rem 2rem 2rem;
		transform: rotate(-90deg);
		transform-origin: top left;
		width: 90vh;
	    min-width: 90vh;
	    max-width: 90vh;
	    height: 90vw;
	    min-height: 80vw;
	    max-height: 90vw;
	    min-height: unset;
	    top: 95vh !important;
	    left: 5vw !important;
		bottom: unset;
		left:unset;
		right: unset;
		border-radius: 5px;
		.orbit {
			min-height: unset !important;
			ul.orbit-container {
				max-height: 100% !important;
			}
		}
		.description {
			left: 0;
			top: 0;
		}
	}
 }
#projects {
	.splash-gallery#project-gallery .splash-gallery-wrapper {
		.image-tile:nth-child(2) a {
			background-image: url(../img/landing/gallery/bayfront/bayfront-1.jpg) !important;
		}
		.image-tile:nth-child(3) a {
			background-image: url(../img/landing/gallery/harvest/harvest-2.jpg) !important;
		}
		.image-tile:nth-child(10) a {
			background-image: url(../img/landing/gallery/vandoren/vandoren-4.jpg) !important;
		}
		.image-tile:nth-child(13) a {
			background-image: url(../img/projects/inspiration-1.jpg) !important;
		}
	}
}



















