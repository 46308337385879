//colors



$blue-24: #006689;
$midBlue-24: #00abbd;
$lightBlue-24: #96c4d7;
$darkBlue-24: #004161;

$brown-24: #7a6569;

$red-24: #af2e4e;

$orange-24: #df6d27;
$midOrange-24: #ed9a22;
$lightOrange-24: #f1cb8d;

$silver-24: #c2c5c7;
$slate-24: #1e242c;

$green-24: #588534;
$midGreen-24: #bed747;
$lightGreen-24: #e8eeae;
$darkGreen-24: #005d34;

// $d-red: #d70036;
$d-red: $red-24;
// $l-red: #ff5000;
$l-red: $orange-24;
$l-red-des: #ff7c40;
$d-blue: #003a70;
// $l-blue: #007da4;
$l-blue: $blue-24;
$l-blue-des: #409ebb;
$d-green: #144733;
// $l-green: #cfde00;
$l-green: $midGreen-24;
$l-green-des: #dbe640;
$alt-blue: #1e252b;
// $alt-red: #7b6569;
$alt-red: $brown-24;
$l-gray: #c3c6c8;
$sky-blue: #76c4d5;
// $alt-yellow: #f5c400;
$alt-yellow: $midOrange-24;

//typography
$trenda: "Trenda W00 Regular", helvetica, arial, sans-serif;
$trendaBold: "Trenda W00 Bold", helvetica-bold, arial, sans-serif;

$avenir: "AvenirNextLTW01-Condens_721299", sans-serif;
$avenir-demi: "AvenirNextLTW01-DemiCn", sans-serif;
$avenir-med: "AvenirNextLTW01-MediumC", sans-serif;
//animation
$trans-typ: .3s ease;


