#home {

	p {
		font-size: 18px;
		font-style: $trenda;
		font-weight: 600;
	}
	h3 {
		font-family: $avenir-demi;
		font-size: 2.667rem;
		line-height: 1.2;
		margin-bottom: 1rem;
		color: $blue-24;
		font-weight:700;
		&.bigger {
			font-size: 3.33rem;
			margin-bottom: 2rem;
		}
	}
	section {
		padding: 3rem 0;
	}
	.splash {
		display: flex;
		flex-direction: column;
		height: 100vh;
		padding: 0;
		.splash-image {
			display: flex;
			flex-grow: 1;
			position:relative;
			.image-rotator {
				img {
					position: absolute;
					width: 100%;
					height: 100%;
					opacity: 0;
					transition: opacity 1s ease-in-out;
					object-fit: cover;
					&.active {
						opacity: 1;
					}
				}
			}
		}
		.splash-text {
			position: absolute;
			bottom: 0;
			left: 4rem;
			color: white;
			font-family: $trendaBold;
			font-size: 6rem;
			text-shadow: 3px 3px 5px rgba(0,0,0,.1), -3px 3px 5px rgba(0,0,0,.1), -3px -3px 5px rgba(0,0,0,.1), 3px -3px 5px rgba(0,0,0,.1),
		}
		.header {
			position: fixed;
			width: 100%;
			z-index: 15;
			.masthead {
				display: flex;
				background-color: rgba(255,255,255,.75);
				padding: 0 3.333rem;
				height: 4rem;
				transition: height $trans-typ, background-color $trans-typ;
				position: relative;

				.logo {
					display: flex;
					align-content: center;
				}
				.navigation {
					margin-left: auto;
					margin-right: auto;
				}
				.find-a-playground {

				}
				.logo-aligned {
					transition: opacity $trans-typ;
				}

				.logo-aligned {
					position:absolute;
					top: 10px;
					opacity: 1;
					img { width: 250px; }
				}
				.logo-icon {
					display: none;
					margin-top: 5px;
					img {
						height: 50px;
					}
				}
				.header-nav.stack {
					margin: 0;
					min-width: 0;
					display: inline-block;
					position: absolute;
					left: 50%;
					transform: translate(-50%);
					top: 1.33rem;
					white-space: nowrap;
					>div {
						display: inline-block;
						padding: 0;
						border: none;
						a {
							display: inline;
							color: $alt_red;
							font-family: $avenir-demi;
							font-size: 1.2rem;
							float: none !important;
							padding-right: 1rem;
							margin-right: 1rem;
							border-right: 1px solid $alt-red;
						}
						&.secondary-nav a:last-child {
							padding-right: 0;
							margin-right: 0;
							border-right: none;
						}
					}
				}
				.header-nav.small {
					display: none;
					position: absolute;
					right: 0;
					bottom: 1rem;

					.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
						margin-top:3px;
					}
					.menu {
						margin-top: .5rem;
						padding-right: 2rem;
						font-family: $avenir-demi;
						padding-bottom: .2667rem;
						>li a { padding-bottom: 0;}
						a {
							color: $alt_red;
						}
						.submenu {
							width: calc(100% + 2rem);
							margin-right: -2rem;
							background-color: transparentize($alt_red, .25);
							border-color: $alt_red;
							li {
								transition: background-color $trans-typ;
								width: calc(100% + 2rem);
								a {
									padding: 1rem 0 1rem 1rem;
									color: white;
								}
								&:hover {
									background: transparentize($alt_red, .2);
								}
							}
						}
					}
				}
				.find-a-playground {
					display: flex;
					justify-content: center;
					align-content: center;
					flex-wrap: wrap;
					.project-map-link {
						display: block;
						height: 55px;
						padding-top: 18px;
						font-family: $trendaBold;
						text-shadow: 1px 1px 0 white, -1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white;
						background-image: url(../img/marker-icon.png);
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						height: 36px;
						padding-top: 12px;
					}
				}
			}
			.is-stuck {
				.window {pointer-events: none;}
				.masthead {
					border-bottom: 1px solid $l-gray;
					background-color: rgba(255,255,255,.9);
				}
			}
		}
	}
	.channel {
		max-width: 2100px;
		padding: 0 7.5rem;
		margin: 0 auto;
	}
	.the-ids-difference {
		position: relative;
		padding: 2rem 0;
		background-color: $red-24;

		.section-content {
			color: white;
			background-color: unset;
			h3 {
				line-height: 1.2;
				color: $midOrange-24;
				margin-bottom: 1rem;
			}
		}
	}
	.explainer {
		h3, p {
			color: $red-24;
			font-weight:800;
		}
		.channel {
			max-width: 1700px;
		}
		.copy-constrainer {
			padding-right: 4rem;
			p {
				color: $slate-24;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.image-container {
			padding-left: 2rem;
			background-image: url(../img/landing/art-of-play.jpg);
			background-size: cover;
			background-position: top;
			border-radius: 5px;
		}
	}
	// .splash-gallery {

	// 	h3 {
	// 		color: $red-24;
	// 		margin: 1rem 0 3rem;
	// 		text-align: center;
	// 	}
	// 	.splash-gallery-wrapper {

	// 		.image-tile{ 
	// 			height: 23.44vw;
	// 			margin-bottom: 5px;
	// 			.tile-wrapper {
	// 				width: 100%;
	// 				height: 100%;
	// 				overflow: hidden;
	// 				position: relative;
	// 				transition: outline ease .2s;
	// 				border-radius: 5px;
	// 			}
	// 			a {
	// 				display: block;
	// 				width: 100%;
	// 				height: 100%;
	// 				background-size: cover;
	// 				background-position: center;
	// 				transition: all ease .3s;
	// 				&.image-center {
	// 					background-position: center;
	// 				}
	// 			}
	// 			.tile-info {
	// 				position: absolute;
	// 				bottom: 0;
	// 				left: 0;
	// 				border-radius: 0 0 5px 5px;
	// 				background: rgba(255,255,255,.8);
	// 				width: 100%;
	// 				padding: 5px 10px;
	// 				opacity: 0;
	// 				transition: opacity ease .3s;
	// 				z-index: 10;
	// 				h4 {
	// 					font-weight: 600;
	// 					font-family: $trendaBold;
	// 					color: $slate-24;
	// 					margin-bottom: 0;
	// 				}
	// 				div {
	// 					font-family: $avenir;
	// 					font-size: 18px;
	// 					color: $slate-24;
	// 					margin-top: -.33rem;
	// 				}
	// 			}
	// 			&:hover {
	// 				.tile-wrapper {
	// 					outline: 2px solid $lightBlue-24;
	// 				}
	// 				a {
	// 					transform: scale(1.1);
	// 				}
	// 				.tile-info {
	// 					opacity: 1;
	// 				}
	// 			}
	// 		}

	// 		.image-tile:nth-child(1) a{
	// 			background-image: url(../img/landing/gallery/blanchard-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(2) a{
	// 			background-image: url(../img/landing/gallery/harvest-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(3) a{
	// 			background-image: url(../img/landing/gallery/bayfront-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(4) a{
	// 			background-image: url(../img/landing/gallery/wardle-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(5) a{
	// 			background-image: url(../img/landing/gallery/riverfront-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(6) a{
	// 			background-image: url(../img/landing/gallery/heirloom-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(7) a{
	// 			background-image: url(../img/landing/gallery/meadow-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(8) a{
	// 			background-image: url(../img/landing/gallery/cook-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(9) a{
	// 			background-image: url(../img/landing/gallery/firstcreek-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(10) a{
	// 			background-image: url(../img/landing/gallery/vandoren-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(11) a{
	// 			background-image: url(../img/landing/gallery/basalt-tile.jpg);
	// 		}
	// 		.image-tile:nth-child(12) a{
	// 			background-image: url(../img/landing/gallery/crescendo-tile.jpg);
	// 		}
	// 		//cell horizontal margins above highest break-point
	// 		.image-tile:nth-child(3n+1) {
	// 			padding-right: 5px;
	// 		}
	// 		.image-tile:nth-child(3n) {
	// 			padding-left: 5px;
	// 		} 
	// 	}
	// }
	.catalog-explainer {
		padding-bottom: 0;
		padding-top: 4rem;
		.channel {
			justify-content: center;
		}
		.narrative {
			width: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 2rem;
			h3 {
				color: $red-24;
				margin-bottom: 0;
				display: inline;
			}
		}
		.catalog-download {
			font-size: 13px;
			color: $red-24;
			border: 1px solid $red-24;
			border-radius: 5px;
			padding: 1px 5px;
			transition: background .2s ease;
			margin-left: 1.33rem;
			margin-top: 8px;
			&:hover {
				background: transparentize($red-24, .8);
			}
		}
		.catalog-steps {
			margin-bottom: 3rem;
			padding-top: 1rem;
			border-top: 1px solid transparentize($silver-24, .6);
			border-bottom: 1px solid transparentize($silver-24, .6);
			>div {
				padding: 0 1rem;
				&:first-child {
					padding-left: 0;
					padding-right: 2rem;
				}
				&:last-child {
					padding-right: 0;
					padding-left: 2rem;
				}
			}
			h5 {
				font-size: 1.2rem;
				font-family: $trendaBold;
				color: $brown-24;
			}
			p {
				font-weight: normal;
				color: $brown-24;
				font-size: 1.2rem;
			}
		}
		.catalog-item-tile {
			display: flex;
			position: relative;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 0 2rem;
			margin-bottom: 5rem;
			padding-right: 0rem;
			padding-left: 2rem;
			//last cell
			&:nth-child(3n+1) {
				padding-right: 1rem;
				padding-left: 1rem;
			}
			//middle cell
			&:nth-child(3n) {
				padding-left: 0rem;
				padding-right: 2rem;
			}
			p {
				color: $slate-24;
			}
			a {
				position: relative;
				h4 {
					color: $red-24;
					font-family: $avenir-demi;
					font-weight: 600;
					border-bottom: 1px solid transparentize($red-24, 1);
					transition: all $trans-typ;
					&:after {
						content: "> See the full line!";
						font-size: 13px;
						position: absolute;
						top: 1.67rem;
						margin-left: .33rem;
						opacity: 0;
						transition: opacity $trans-typ;
						text-wrap: nowrap;
					}
					&:hover {
						border-bottom: 1px solid $red-24;
						&:after {
							opacity: 1;
						}
					}
				}
			}
			p {
				margin-bottom: 0;
			}
			.carousel {
	            width: 100%;
				padding-top: 33%;
				margin-bottom: 1rem;
				position: relative;
		        img {
		            width: calc(100% - 4rem);
		            height: calc(100% - 1.5rem);
		            object-fit: contain;
		            position: absolute;
		            left: 50%;
		            top: 0;
		            transform: translateX(-50%);
		        }
		        .carousel-controls {
		        	display: inline-flex;
		        	width: 100%;
					button {
			            color: $silver-24;
			            font-size: 1.8rem;
			            width: 7rem;
			            height: 1.5rem;
			            cursor: pointer;
			            line-height: 1.5rem;
			            text-align: center;
			            transition: outline $trans-typ;
			            outline: 1px solid transparent;
			            border-radius: 5px;
			            &.prev {
			            	left: 1rem;
			            }
			            &.next {
			            	margin-left: auto;
			            	right: 1rem;
			            }
			            &:hover {
			            	outline: 1px solid #ebebeb;
			            }
			            span {
			            	line-height: 1.5rem;
			            }
			        }
			    }
			}
		}
	}

	.inspire {
		position: relative;
		width: 100vw;
		height: 67vh;
		.copy {
			height: 100%;
		}
		img {
			position:absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}
		h3 {
			z-index: 10;
			color: white;
			align-self: end;
			text-shadow: 1px 1px 5px rgba(0, 0, 0, .35);

		}
	}
	.installations {
		.copy {
			display: flex;
			padding-right: 4rem;
			min-height:40vh;
			>div {
				align-self: center;
			}
			h3 {
				color: $midBlue-24;
			}
		}

		.locations-wrapper {
			padding-left: 2rem;
			position: relative;
			padding-top: 25%;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: none;
				border-radius: 5px;
			}
		}
	}
}
.landing-footer {
		.links {
			padding: 2rem 4rem 0 !important;
			position: relative;
			display: flex;
			justify-content: center;
			max-width: unset;
			.primary {
				margin-right: 2.33rem;
			}
			.about {
				&:after {
					left: 0 !important;
				}
			}
			.specs {
				margin-left: 2.33rem;
			}
			img {
				position: absolute;
				right: 4rem;
				bottom: .25rem;
				height:80px;
				opacity: .7;
			}
		}
	}

// //landing modals
// .landing-modal {
// 	&.reveal.large {
// 		width: calc(100vw - 2rem);
// 		max-width: unset;
// 		height: calc(100vh - 2rem);
// 		max-height: unset;
// 		top: 1rem !important;
// 		padding: 2rem;

// 		.content-wrapper {
// 			height: 100%;
// 			overflow: hidden;
// 			.close-button {
// 				color: red;
// 				z-index: 100;
// 				width: 30px;
// 				height: 30px;
// 				background: rgba(255,255,255,.8);
// 				border-radius: 15px;
// 				border: 1px solid $slate-24;
// 				color: $slate-24;
// 				top: .5rem;
// 				right: .5rem;
// 				line-height: 0;
// 				span {
// 					line-height: 0;
// 				}
// 			}
// 		}
// 		.orbit {
// 			height: 100%;
// 		}
// 		nav.orbit-bullets {
// 			margin: 0;
// 			padding: 0;
// 			padding-right: 1rem;
// 			height: 100%;
// 			overflow-y: auto;
// 			border-radius: 5px;
// 			button {
// 				width: 100%;
// 				padding-top: 100%;
// 				border-radius: 0;
// 				margin: 5px 0;
// 				position: relative;
// 				border-radius: 5px;
// 				overflow: hidden;
// 				cursor: pointer;
// 				&:first-child {
// 					margin-top: 0;
// 				}
// 				&:last-child {
// 					margin-bottom: 0;
// 				}
// 				img {
// 					position: absolute;
// 					top: 0;
// 					left: 0;
// 					width: 100%;
// 					height: 100%;
// 					object-fit: cover;
// 					transition: all ease .15s;
// 					&.thumb-top {
// 						object-position: top;
// 					}
// 					&.thumb-left {
// 						object-position: left;
// 					}
// 					&.thumb-right {
// 						object-position: right;
// 					}
// 				}
// 				&:hover {
// 					img {
// 						transform: scale(1.1);
// 					}
// 				}
// 				&.is-active {
// 					img {
// 						transform: scale(1.1);
// 					}
// 					&:after {
// 						content: "";
// 						display: block;
// 						width: 100%;
// 						height: 100%;
// 						position: absolute;
// 						left: 0;
// 						top: 0;
// 						border: 3px solid $slate-24;
// 						border-radius: 5px;
// 					}
// 				}
// 			}
// 		}
// 		.focus-panel {
// 			display: flex;
// 			flex-direction: column;
// 			height: 100%;
// 			overflow: hidden;
// 			padding-left: 1rem;
// 			.orbit-wrapper {
// 				flex-shrink:1;
// 				flex-grow: 1;
// 				position: relative;
// 				.orbit-controls {
// 					position: absolute;
// 					width: 100%;
// 					height: 100%;
// 				}
// 				ul.orbit-container {
// 					height: 100% !important;
// 					width: 100%;
// 					max-height: unset;
// 					min-height: unset;
// 					border-radius: 5px;
// 					li, figure, img{
// 						height: 100%;
// 						width: 100%;
// 					}
// 					img {
// 						object-fit: cover;
// 						&.vert {
// 							object-fit: contain;
// 							background-color: $slate-24;
// 						}
// 						&.image-bottom {
// 							object-position: center bottom;
// 						}
// 					}
// 					.orbit-caption {
// 						display: none;
// 					}
// 				}
// 			}
// 			.project-details {
// 				.project-name {
// 					margin-top: .67rem;
// 					display: flex;
// 					align-items: baseline;
// 				}
// 				h1 {
// 					font-family: $avenir-demi;
// 					font-size: 2rem;
// 					color: #1f252f
// 				}
// 				span {
// 					font-family: $avenir;
// 					font-size: 2rem;
// 					color: #1f252f;
// 					padding-left: .5rem;
// 					margin-left: .5rem;
// 					border-left: 1px solid #1f252f;
// 					line-height: 1;
// 				}
// 				p {
// 					margin-bottom: 0;
// 					font-size: 1.2rem;
// 				}
// 			}
// 		}
// 	}
// }
@media (max-width: 1100px) {
	.header, .header.is-stuck{
		.logo-icon {
			display: block !important;
		}
		.logo-aligned {
			display: none;
		}
	}
	#home {
		.channel {
		padding: 0 3rem;
		}
		h3.bigger {
			font-size: 2.76rem;
		}
		.splash .splash-text {
			left: 2rem;
			font-size: 5rem;
		}
	}
}
@media (max-width: 900px) {
	#home {
		p {
			font-size: 15px;
		}
		.splash-gallery {
			h3 {
				margin: 0 0 2rem;
				font-size: 2rem !important;
			}
			.splash-gallery-wrapper .image-tile .tile-info {
				h4 {
					font-size: 1.5rem;
				}
			}
		}
		.catalog-explainer {
			padding-top: 2rem;
			.narrative {
				display: block;
				margin-bottom: 1rem;
				h3 {
					display: block;
					font-size: 2rem;
					text-align: center;
				}
				a {
					display: inline-block;
					margin-left: 50%;
					transform: translateX(-50%);
				}
			}
			.catalog-steps {
				font-size: 1rem;
				h5, p {
					font-size: 1rem;
				}
			}
			.catalog-item-tile {
				a h4 {
					font-size: 1.33rem;
				}
				p {
					font-weight: normal;
				}
			}
		}
		.installations .copy h3 {
			font-size: 2rem;
		}
	}
	.landing-modal.reveal.large {
		padding: 1rem;
		.focus-panel {
			padding-left: 0;
			.project-details {
				h1, span {
					font-size: 1.5rem;
					margin-bottom: 0;
				}
				p {
					font-size: 1rem;
				}
			}
		}
	}
}
@media (max-width: 750px) {
	#home {
		.splash .header .masthead {
			padding: 1.67rem;
		}
		.logo-aligned {
			display: block;
		}
		.logo-icon {
			display: none !important;
		}
		.header-nav.stack {
			display: none !important;
		}
		.header-nav.small {
			display: block !important;
		}
		.find-a-playground {
			display: none !important;
		}
		.channel {
			padding: 0 1.67rem;
		}
	}
} 
@media (max-width: 640px) {
	#home {
		p {
			font-weight:500 !important;
		}
		// .splash-gallery .splash-gallery-wrapper .image-tile {
		// 	height: 20vh;
		// 	padding: 0 !important;
		// 	&:nth-child(2n+1) {
		// 		padding-right:5px !important;
		// 	}
		// }
		.explainer {
			padding-top: 1rem;
			padding-bottom: 2rem;
			.copy-constrainer {
				padding-right: 0;
			}
		}
		.catalog-explainer {
			.catalog-steps {
				text-align: left;
				>div {
					padding: 0 !important;
				}
			}
			.catalog-item-tile {
				padding: 0 1rem 0 0 !important;
				margin-bottom: 3rem;
			}
		}
		.installations {
			.copy {
				min-height: unset;
			}
			.locations-wrapper {
				padding-top: 60%;
			}
		}
	}
	.landing-footer {
		.links {
			img {
				display: none;
			}
			.primary {
				margin-right: 4.33rem;
			}
			.specs {
				margin-left: 4.33rem;
			}
		}
	}
}


