
input {
	border-radius: 0;
}
a:active, a:focus {
	outline: none;
}
a.button {
	width: 150px;
	height: 40px;
	border-radius: 5px;
	font-size: 1rem;
	font-family: $avenir-demi;
	box-shadow: 0 0 3px 1px transparentize(black, .9);
	transition: background-color $trans-typ;


	&.white {
		background-color: transparentize(white, .07);
		color: $primary-color;
		&:hover {
			background-color: white;
		}
	}
	&.red {
		background-color: $d-red;
		&:hover {
			background-color: lighten($d-red, 5%);
		}
	}
}
.dropdown.menu li a {
	background: transparent;
}
a.act_link_white {
	color: transparentize(white, .2);
	&:hover {
		color: white;
	}
}
.header-stnd {
	.masthead {
		background: white;
		top: 0;
		width: 100%;
		height: 4rem;
		padding: 0 3.333rem;
		border-bottom: 2px solid $l-gray;

		.logo-left {
			opacity: 1;
			position: absolute;
			top: .67rem;
			img { width: 250px;}
		}
		.logo-icon { 
			display: none;
			position: absolute;
			top: 5px;
			img {
				height: 50px;
			}
		}
		.header-nav {
			margin: 0;
			min-width: 35rem;
			display: inline-block;
		    position: absolute;
		    left: 50%;
		    transform: translate(-50%);
		    top: 1.33rem;

			a {
				font-family: $avenir-demi;
				color: $secondary-color;
				display: inline;
				font-size: 1.2rem;
				padding-right: 1rem;
				&:not(:last-child) {
					margin-right: 1rem;
					border-right: 1px solid $alt-red;
				}
				&.active {
					color: $d-red;
					position: relative;
					&:after {
						content: "";
						display: block;
						width: 100%;
						height: 3px;
						background-color: $d-red;
						position: absolute;
						bottom: -3px;
						left: -.5rem;
					}
				}
			}
		}
		&.small {
			position: fixed;
			display: none;
			align-items: flex-end;
			z-index: 20;
			padding: 0 0 1rem 3.333rem;
			.identity {
				flex-grow: 1;
				padding-right: 1rem;
				img { max-height: 28px;}
			}
			.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
				margin-top:3px;
			}
			.menu {
				margin-top: .5rem;
				padding-right: 2rem;
				font-family: $avenir-demi;
				padding-bottom: 0;
				>li a { padding-bottom: 0;}
				a {
					color: $alt_red;
				}
				.submenu {
					width: calc(100% + 2rem);
					margin-right: -2rem;
					li {
						transition: background-color $trans-typ;
						width: calc(100% + 2rem);
						a {
							padding: 1rem 0 1rem 1rem;
							color: white;
						}
						&:hover {
							background: transparentize($l_blue, .2);
						}
					}
				}
			}
		}
	}
}
.page-content {
	max-width: 1440px;
	margin: auto;
}

.footer {
	background-color: $alt-red;

	.links {
		padding: 2rem 1.5rem 0;
		max-width: 840px;
		margin: 0 auto;
		color: white;
		a { 
			font-family: $avenir-med;
			color: white;
			&:hover {
				text-decoration: underline;
			}
		}

		ul {
			margin-bottom: 0;
			li {
				line-height: 1;
				&:last-child { padding-bottom: 0 !important;}
			}
			&.primary {
				font-size: 1.2rem;
				text-transform: uppercase;
				font-family:$avenir-med !important;
				li {
					padding-bottom: 3rem;
				}
			}
			&:not(.primary) {
				li {
					padding-bottom: .667rem;
					&:first-child {
						font-size: 1.2rem;
						text-transform: uppercase;
						font-family: $avenir-med !important;
					}
				}
			}
			&.about {
				padding-left: 2.33rem;
				position: relative;
				&:after {
					content: "";
					border-left: 1px solid transparentize(white, .3);
					position: absolute;
					top: 0;
					left: -1rem;
					height: 9.667rem;
					display: block;
				}
			}
		}
		.map {
			span {
				font-family: $avenir;
				line-height: 1;
				float: left;
				margin-bottom: .333rem;
			}
			a {
				display: block;
				float: left;
				width: 100%;
				height: 8rem;
				background-image: url('../img/sm_map.jpg');
				background-position: center;
				background-size: cover;
			}
		}
		.contact-form-link.disabled {
			opacity: .3;
			pointer-events: none;
		}
	}
	.icons {
		text-align: center;
		height: 50px;
		width: calc(100% - 8rem);
		margin-left: 4rem;
		border-top: 1px solid transparentize(white, .5);
		margin-top: 1rem;
		position: relative;
		.industry img{
			position: absolute;
			left: 0;
			height: 30px;
			margin-top: 10px;
			opacity: .5;
		}
		.contact {
			font-family: $avenir;
			color: white;
			font-size: .867rem;
			line-height: 50px;
		}
		.social {
			position: absolute;
			right: 0;
			top: 7px;
			a {
				opacity: .5;
				margin-left: .333rem;
				width: 35px;
				height: 35px;
				display: inline-block;
				transition: opacity $trans-typ;
				&:hover {
					opacity: .8;
				}
			}
			.fb {
				background-image: url(../img/fb_badge.svg);
			}
			.insta {
				background-image: url(../img/instagram_badge.svg);
			}
		}
	}
}
.reveal-overlay { background-color: rgba(10, 10, 10, .85); }
.reveal { box-shadow: 0,0,0, transparentize(black, .5)}


//landing modals
.landing-modal {
	&.reveal.large {
		width: calc(100vw - 2rem);
		max-width: unset;
		height: calc(100vh - 2rem);
		max-height: unset;
		top: 1rem !important;
		padding: 2rem;

		.content-wrapper {
			height: 100%;
			overflow: hidden;
			.close-button {
				color: red;
				z-index: 100;
				width: 30px;
				height: 30px;
				background: rgba(255,255,255,.8);
				border-radius: 15px;
				border: 1px solid $slate-24;
				color: $slate-24;
				top: .5rem;
				right: .5rem;
				line-height: 0;
				span {
					line-height: 0;
				}
			}
		}
		.orbit {
			height: 100%;
		}
		nav.orbit-bullets {
			margin: 0;
			padding: 0;
			padding-right: 1rem;
			height: 100%;
			overflow-y: auto;
			border-radius: 5px;
			button {
				width: 100%;
				padding-top: 100%;
				border-radius: 0;
				margin: 5px 0;
				position: relative;
				border-radius: 5px;
				overflow: hidden;
				cursor: pointer;
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: all ease .15s;
					&.thumb-top {
						object-position: top;
					}
					&.thumb-left {
						object-position: left;
					}
					&.thumb-right {
						object-position: right;
					}
				}
				&:hover {
					img {
						transform: scale(1.1);
					}
				}
				&.is-active {
					img {
						transform: scale(1.1);
					}
					&:after {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						border: 3px solid $slate-24;
						border-radius: 5px;
					}
				}
			}
		}
		.focus-panel {
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow: hidden;
			padding-left: 1rem;
			.orbit-wrapper {
				flex-shrink:1;
				flex-grow: 1;
				position: relative;
				.orbit-controls {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				ul.orbit-container {
					height: 100% !important;
					width: 100%;
					max-height: unset;
					min-height: unset;
					border-radius: 5px;
					li, figure, img{
						height: 100%;
						width: 100%;
					}
					img {
						object-fit: cover;
						&.vert {
							object-fit: contain;
							background-color: $slate-24;
						}
						&.image-bottom {
							object-position: center bottom;
						}
					}
					.orbit-caption {
						display: none;
					}
				}
			}
			.project-details {
				.project-name {
					margin-top: .67rem;
					display: flex;
					align-items: baseline;
				}
				h1 {
					font-family: $avenir-demi;
					font-size: 2rem;
					color: #1f252f
				}
				span {
					font-family: $avenir;
					font-size: 2rem;
					color: #1f252f;
					padding-left: .5rem;
					margin-left: .5rem;
					border-left: 1px solid #1f252f;
					line-height: 1;
				}
				p {
					margin-bottom: 0;
					font-size: 1.2rem;
				}
			}
		}
	}
}
.splash-gallery {

	h3 {
		color: $red-24;
		margin: 1rem 0 3rem;
		text-align: center;
	}
	.splash-gallery-wrapper {

		.image-tile{ 
			height: 23.44vw;
			margin-bottom: 5px;
			.tile-wrapper {
				width: 100%;
				height: 100%;
				overflow: hidden;
				position: relative;
				transition: outline ease .2s;
				border-radius: 5px;
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				transition: all ease .3s;
				&.image-center {
					background-position: center;
				}
			}
			.tile-info {
				position: absolute;
				bottom: 0;
				left: 0;
				border-radius: 0 0 5px 5px;
				background: rgba(255,255,255,.8);
				width: 100%;
				padding: 5px 10px;
				opacity: 0;
				transition: opacity ease .3s;
				z-index: 10;
				h4 {
					font-weight: 600;
					font-family: $trendaBold;
					color: $slate-24;
					margin-bottom: 0;
				}
				div {
					font-family: $avenir;
					font-size: 18px;
					color: $slate-24;
					margin-top: -.33rem;
				}
			}
			&:hover {
				.tile-wrapper {
					outline: 2px solid $lightBlue-24;
				}
				a {
					transform: scale(1.1);
				}
				.tile-info {
					opacity: 1;
				}
			}
		}

		.image-tile:nth-child(1) a{
			background-image: url(../img/landing/gallery/blanchard-tile.jpg);
		}
		.image-tile:nth-child(2) a{
			background-image: url(../img/landing/gallery/harvest-tile.jpg);
		}
		.image-tile:nth-child(3) a{
			background-image: url(../img/landing/gallery/bayfront-tile.jpg);
		}
		.image-tile:nth-child(4) a{
			background-image: url(../img/landing/gallery/wardle-tile.jpg);
		}
		.image-tile:nth-child(5) a{
			background-image: url(../img/landing/gallery/riverfront-tile.jpg);
		}
		.image-tile:nth-child(6) a{
			background-image: url(../img/landing/gallery/heirloom-tile.jpg);
		}
		.image-tile:nth-child(7) a{
			background-image: url(../img/landing/gallery/meadow-tile.jpg);
		}
		.image-tile:nth-child(8) a{
			background-image: url(../img/landing/gallery/cook-tile.jpg);
		}
		.image-tile:nth-child(9) a{
			background-image: url(../img/landing/gallery/firstcreek-tile.jpg);
		}
		.image-tile:nth-child(10) a{
			background-image: url(../img/landing/gallery/vandoren-tile.jpg);
		}
		.image-tile:nth-child(11) a{
			background-image: url(../img/landing/gallery/basalt-tile.jpg);
		}
		.image-tile:nth-child(12) a{
			background-image: url(../img/landing/gallery/crescendo-tile.jpg);
		}
		.image-tile:nth-child(13) a{
			background-image: url(../img/landing/gallery/inspiration-tile.jpg);
		}
		.image-tile:nth-child(14) a{
			background-image: url(../img/landing/gallery/lions-tile.jpg);
		}
		.image-tile:nth-child(15) a{
			background-image: url(../img/landing/gallery/sunbird-tile.jpg);
		}
		//cell horizontal margins above highest break-point
		.image-tile:nth-child(3n+1) {
			padding-right: 5px;
		}
		.image-tile:nth-child(3n) {
			padding-left: 5px;
		} 
	}
}

@media (max-width: 1260px) {
  .header-stnd {
    .logo-icon {
    	display: block !important;
    }
    .logo-left {
    	display: none;
    }
  }
}
@media (max-width: 800px) {
	.page-wrapper {
		margin-top: 4rem;
	}
	.header-stnd {
		.masthead {
			&.medium {
				display: none;
			}
			&.small {
				display: flex;
			}
		}
	}
}
@media (max-width: 640px) {
	.hide-on-mobile { display:none;}
	.footer {
		.links {
			margin-left: 1rem;
			margin-right: 1rem;
			.contact-form-link { display: none;}
			ul {
				padding: 0 !important;
				li {

				}
				&:after { display: none !important;}
			}
			.map {
				margin-top: 2rem;
			}
		}
		.icons {
			width: calc(100% - 4rem);
			margin-left: 2rem;
			.industry img{
				height:25px;
			}
			.social {
				top: 10px;
				a {
					width: 25px;
					height: 25px;
				}
			}
			.contact {
				margin-left: -25px;
			}
		}
	}
	.splash-gallery .splash-gallery-wrapper .image-tile {
		height: 20vh;
		padding: 0 !important;
		&:nth-child(2n+1) {
			padding-right:5px !important;
		}
	}
}
