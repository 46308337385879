.info-page {
	&.page-wrapper {
		padding: 0 4rem;

		.lead-block {
			margin-top: 1rem;
			height: 75vh;
			position: relative;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			span.img-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				z-index: -1;
			}

			.narrative {
				>div {
					padding: 1.333rem;
					background-color: transparentize($midBlue-24, .05);
					border: {
						width: 0 4px 4px 0;
						style: solid;
						color: white;
						radius: 0 0 5px 0;
					}
					h1 {
						color: white;
						font-size: 2.4rem;
						font-family: $avenir-demi;
					}
					p {
						color: white;
						font-family: $trenda;
						line-height: 1.8rem;
						font-weight: 600;
					}
				}
			}
		}
		.feed {
			p { text-align: justify; }
			max-width: 1440px;
			margin: auto;
			justify-content: center;
			section {
				margin: 2.667rem 0;
				padding: 2.667rem 0;
				justify-content: center;
				position: relative;

				&.center {
					border: {
						width: 1px 0;
						color: $l-gray;
						style: solid;
					}
				}
				a.nav-link {
					position: absolute;
					top: -8rem;
				}
				h2 {
					font-size: 2rem;
					color: $alt-red;
					font-family: $avenir-demi;
				}
				.image-block {
					min-height: 33vh;
					.image-container {
						background-size: cover;
						background-position: center;
						position: relative;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							position:absolute;
						}
					}
				}
				.description {
					p:last-child { margin-bottom: 0;}
				}
				&.left {
					.image-block {
						padding-right: 1.333rem;
						position: relative;

						.image-container {
							margin-left: auto;
						}

						&:before {
							content: "";
							position: absolute;
							display: solid;
							width: 11%;
							height: 100%;
							background-color: $midBlue-24;
						}
					}
					.description {
						padding-left: 1.333rem;
					}
				}
				&.right {
					.image-block {
						padding-right: 1.333rem;
						position: relative;
						.image-container {
							width: 100%;
							height: 100%;
						}
					}
					.description {
						padding-left: 1.333rem;
						padding-right: calc(9.5% + 1.33rem);
						position: relative;
						&:after {
							content: "";
							position: absolute;
							height: 100%;
							width: 9.5%;
							background-color: $midBlue-24;
							right: 0;
							top: 0;
						}
					}
				}
				&.wide {
					margin: 0;
					padding: 0;
					.description {
						padding-right: 1.333rem;
					}
					.image-block {
						padding-left: 1.333rem;
						position: relative;

						.image-container {
							width: 100%;
							height: 100%;
						}
					}
				}
				&.land {
					border: none;
					margin-top: 0;
					padding-top: 0;
					.image-block .image-container { 
						background-size: contain;
						background-repeat: no-repeat;
						border: 1px solid $l-gray;
					}
				}
			}
		}
	}
	&#custom {
		.combo {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
			a {
				float:left;
				clear: both;
			}
			p { margin-bottom: 2rem !important;}
		}
		section {
			&.combo, &.colab {
				a {
					font-family: $avenir-demi;
				    color: $l_blue;
				    background-image: url(../img/act_link_arrow_bl.png);
				    background-repeat: no-repeat;
				    background-position: right center;
				    width: auto;
				    padding-right: 1.33rem;
				    transition: color 0.3s ease;
				}
			}
			&.colab {
				margin: 2.667rem 0;
				padding: 2.667rem 0;
				border-bottom: 1px solid $l-gray;
				border-top: 1px solid $l-gray;
				a {
					float:right;
				}
			}
		}
	}
	&#about {
		section.left .image-block:before, section.right .description:after {
			background-color: $midGreen-24;
		}
		.narrative {
			>div {
				padding: 1rem 2rem .667rem;
				background-color: transparent;
				border: none;
				h1 {
					color: white;
					text-shadow: 0 0 7px transparentize(black, 0.7);
				}
			}
		}
		.custom-lead {
			height: calc(100vh - 8rem);
			display: flex;
			flex-direction: column;
			max-height: 800px;
			.lead {
				background: $midGreen-24;
				font-family: $trenda;
			    color: white;
			    font-size: 17px;
			    margin-top: 4px;
			    padding: 1rem 2rem;
			    font-family: $trenda;
			    column-count: 2;
			    column-gap: 3rem;
			}
		}
		section {
			h2 { color: $alt-red;}
			&.craft {
				margin: 0;
				padding: 0;
				border: none;
			}
			&.history {
				margin-top: 0;
				padding-top: 0;
				border-top: none;
				margin-bottom: 2.667rem;
				padding-bottom: 2.667rem;
				max-width: 1000px;

				.image-container {
					width: 33%;
					height: 30vh;
					background-size: cover;
					background-repeat: no-repeat;
					float: right;
					margin-bottom: 1rem;
					margin-left: 2rem;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
			// &.jobs {
			// 	margin-bottom: 2.667rem;
			// 	.job-link {
			// 		text-align: center;
			// 	}
			// }
			.block-quote {
				font-size: 36px;
				font-family: $avenir-demi;
				color: $blue-24;
				font-style: italic;
			}
			.attribution {
				font-family: $avenir;
				color: $l-gray;
				margin-top: 1.333rem;
			}
			.description {
				&.full-width {
					padding-left: 2rem;
					border-left: 1rem solid $l-green;

					.p-wrapper {
						p:last-child {
							margin-bottom: 0;
							a {
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
	&#specs {
		.custom-lead .lead-block { height: 50vh; }
		.narrative {
			>div {
				padding: 1rem 2rem .667rem;
				background-color: transparent;
				border: none;
				h1 {
					color: white;
					text-shadow: 0 0 7px transparentize(black, 0.85);
				}
			}
		}
		.composition {
			.image-block {
				.image-container {
					background-position: left center;
				}
			}
		}
		.safety {
			margin-top: 0;
			padding-top: 0;
			border-top: none;
			.image-block {
				padding-top: 4rem;
				.image-container {
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center top;
					margin: auto;
					max-width: 10rem;

					a {
						display: block;
						width: 100%;
						height: 100%;
						max-height: 175px;
						max-width: 200px;
					}
				}
			}
			.act_link {
				font-family: $avenir-demi;
			    color: $l_blue;
			    background-image: url(../img/act_link_arrow_bl.png);
			    background-repeat: no-repeat;
			    background-position: right center;
			    width: auto;
			    padding-right: 1.33rem;
			    transition: color 0.3s ease;
			    padding-left: .333rem;
			}
		}
		.install {
			.image-block {
				padding-top: 1.33rem;
			}
		}
		.durability {
			padding-bottom: 0;
			border-bottom: none;
			margin-bottom: 0;
			.image-block {
				.image-container {
					background-position: center top;
				}
			}
		}
		.ropes {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
			&.left {
				.description {
					padding-left: calc(5.5% + 1.33rem);
					position: relative;
					p {
						margin-bottom: 2rem;
					}
					ul {
						margin-left: 2rem;
						li {
							float: left;
							width: 33.33%;
							min-width: 225px;
							max-width: 300px;
							padding-right: 2rem;
							margin-bottom: 2.5rem;
							min-height: 101px;
							img {
								width: 100%;
							}
							span {
								display: block;
								margin-top: .667rem;
								color: $alt-red;
								font-family: $avenir-demi;
								font-size: 1.2rem;
								padding-left: 1rem;
							}
						}
					}
					&:before { 
						content: "";
						position: absolute;
						left: 0;
						display: solid;
						height: calc(100% - 2.5rem);
						background-color: $midBlue-24;
						width: 5.5%; 
					}
				}
			}
		}
		.rock-type {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
			&.right {
				.description {
					padding-right: 0;
					p {
						margin-bottom: 2rem;
					}
					.image-grid {
						div {
							&:first-child {
								padding-right: 1rem;
							}
							&:nth-child(2) {
								padding-left: .35rem;
								padding-right: .65rem;
							}
							&:nth-child(3) {
								padding-left:.65rem;
								padding-right:.35rem;
							}
							&:last-child {
								padding-left: 1rem;
							}
							.img-wrapper {
								width: 100%;
								padding-top: 125%;
								position: relative;
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									position: absolute;
									top:0;
								}
							}
							p {
								margin-top: .33rem;
								color: $alt-red;
								font-family: $avenir-demi;
								font-size: 1.2rem;
								padding-left: .33rem;
							}
						}
					}
					&:after { display: none; }
				}
			}
		}
		.cad {
			border-bottom: 0;
			p {
				margin-bottom: .25rem;
			}

			a {
				font-family: $avenir-demi;
			    color: $l_blue;
			    background-image: url(../img/act_link_arrow_bl.png);
			    background-repeat: no-repeat;
			    background-position: right center;
			    width: auto;
			    padding-right: 1.33rem;
			    transition: color 0.3s ease;
			}
			h2:last-of-type {
				margin-top: 2rem;
			}
			.ar-icon {
				display: inline-block;
				height: 20px;
				width: 20px;
				background-image: url(../img/3d-icon-small.png);
				background-size: contain;
				background-repeat: no-repeat;
				margin-bottom: -2px;
			}
		}
	}
	&#jobs {
		.page-content {
			padding: 0 3.333rem;
			min-height: calc(100vh - 330px);
			h1 {
				margin-top: 2rem;
				padding-left: 2rem;
				font-family: $avenir-demi;
				color: $alt_red;
				font-size: 2.2rem;
				margin-bottom: 1rem;
			}
			.cell > p {
				color: darken($l-gray, 30%);
			}
			section {
				margin-bottom: 1rem;
				padding-bottom: 1rem;
				&:not(:last-child){
					border-bottom: 1px solid #ccc;
				}
				h3 {
					font-size: 1.8rem;
					font-family: $avenir-demi;
					color: #606060;
					margin-bottom: 5px;
				}
				p {
					margin-bottom: 3px;
					font-size: 1.1rem;
					color: darken($l-gray, 30%);
				}
			}
		}
	}
}
@media (max-width: 640px) {
	.page-wrapper {
		padding: 0 1.667rem !important;

		section {
			.section-wrapper {
				position: relative;
				.description {
					padding: 0 !important;
					&:after {
						display: none;
					}
					&:before {
						display: none;
					}
				}
				.image-block {
					position: absolute;
					top: 0;
					height: 25vh;
					padding:0 0 10px 0 !important;
					.image-continer {
						height: 100%;
						width: 100% !important;
					}
					&:before {
						display: none;
					}
				}
			}
		}
		.rock-type {
			.image-grid div { padding: 0 !important; }
		}
	}
	.info-page#about .custom-lead .lead {
		column-count: 1;
	}
}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  	#custom {
  		.ipad-retaining {
  			height: 320px;
  		}
  		.ipad-combos {
  			height: 400px;
  		}
  	}
	#about {
  		.ipad-craft {
  			height: 385px;
  		}
  	}
	#specs {
  		.ipad-safety {
  			height: 200px;
  		}
  		.ipad-install {
  			height: 310px;
  		}
   	}
}











