
#ar-info-modal, #ar-viewer-modal {
	border-radius: 5px;
	padding: 0;
	border: none;
	.close-button { 
		color: $alt-red;
		font-size: 3rem;
		right:2.5rem;
	}
	.modal-header {
		padding: 1rem 2rem 0;
		h3 {
			color: $alt-red;
			font-family: $trendaBold;
			border-bottom: 3px solid $alt-red;
			padding-bottom: 1rem;
		}
	}
}
#ar-info-modal {
	.floating-focus {
		position: absolute;
		width: 100px;
		background-color: lighten($alt-red, 10%);
		border-radius: 10px;
		left: 50%;
		top: 30%;
		border: 5px solid white;
		margin-left: -50px;
		padding: .5rem 1rem;
		font-size: .8678rem;
		color: white;
		font-weight: bold;
		text-align: center;
		img { 
			height:25px; 
			margin: 0 5px;
		}
	}
	.modal-body {
		padding: 0rem 2rem;
		h5 {
			color: $d-red;
			font-family: $avenir-demi;
			font-size: 1.667rem;
			margin-bottom: .667rem;
		}
		.small-narrative { display: none; }
		.compatibility {
			color: #909090;
			font-size: .8667rem;
		}
		.section-description {
			color: $alt-red;
			font-weight: 600;
		}
		.model-wrapper, .image-wrapper { 
			
		}
		.model-viewer-info {
			padding: 0 2rem;
			position: relative;
			.model-wrapper {
				height: 300px; 
				margin-bottom: 1rem;
				width: 400px;
				max-width: 100%;
				margin: 0 auto 1rem;
				padding-bottom: 1rem;
				background-color: #ccc;
				display: flex;
				justify-content: center;
				border-radius: 5px;
				model-viewer {
					height: 100%;
				}
			}
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				border-right: 1px solid $alt-red;
				z-index: -1;
			}
		}
		.ar-info {
			display: flex;
			flex-direction: column;
			padding: 0 2rem 0 4rem;
			.narrative {
				padding: 0 1.33rem 0 1rem;
			}
		}
		.q-t-text {
			color: #909090;
			margin-top: 1rem;
		}
		.q-t-float {
			text-align: center;
			float: right;
			width:30%;
			margin: 0 0 5px 7px;
			img {
			}
		}
		.foot-notes p{
			padding: 0 2rem;
		}
	}
	@media (max-width: 640px) {
		.small-narrative {
			display: block !important;
			margin-top: 1.33rem;
			.q-t-float {
				width: 60px;
			}
			.q-t-text { margin-top: 0; }
		}
		.floating-focus { display: none; }
		.modal-body {
			.model-viewer-info {
				padding: 0;
				&:after {
					display: none;
				}
			}
			.ar-info {
				.narrative {
					padding-left: 0;
				}
				padding: 0;
				.med-narrative { display: none; }
				

			}
		}
		.foot-notes p {
			padding: 0 !important; 
			&:first-of-type { display: none; } 
		}
	}
}

#ar-viewer-modal {
	display: flex;
	flex-direction:column;
	max-width: 700px;
	.modal-header {
		h3 { padding-bottom: 0; }
		.specs {
			div { 
				display: inline;
				color: $alt-red;
				font-family: $trendaBold;
				&.in-label{ font-family: $trenda; }
			}
		}
	}
	.body {
		padding: 0 2rem 1rem;
		position: relative;
		.model-wrapper {
			height: calc(100vh - 400px);
			max-height: 500px;
			position: relative;
			model-viewer {
				margin-top: 1rem;
				border-radius: 5px;
				border: 1px solid #cccccc;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
	.instructions {
		padding: 1rem 2rem;
		color: #909090;
		margin-bottom: 2rem;
		p {
			text-align: justify;
			font-size: .8667rem;
		}
		.icon-wrapper {
			display: inline-block;
			width: 60px;
			position: relative;
			padding-left: 20px;
			img {
				width: 100%;
			}
			span { font-size: .5rem; }
		}
		
	}
	@media (max-width: 640px) {
		border-radius: 0;
		.close-button {
			top: 0 !important;
		}
	}
}